import { createContext, type Dispatch, type SetStateAction } from "react";
import type { HiveContent, ParsedAccount } from "~/utils/hive";

interface PostFilters {
  display: "LEO" | "HIVE";
  sort: "trending" | "latest" | "following" | "";
  tag: string;
}

export const InReplyModalContext = createContext<boolean>(false);
export const ImageModalContext = createContext<boolean>(false);
export const AccountsContext = createContext<any>({});
export const ActivePostViewContext = createContext<
  Dispatch<
    SetStateAction<{ content: HiveContent; author: ParsedAccount } | null>
  >
>(null as any);
export const WalletContext = createContext<any>(null);
export const PostsContext = createContext<HiveContent[]>([]);
export const PostsFiltersContext = createContext<PostFilters>({
  display: "LEO",
  sort: "trending",
  tag: ""
});
export const LeaderboardContext = createContext<any>(null);
